import { columnTypes } from 'components/MyTable/MyTable.constant';
import { useApi } from 'hooks/useApi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { userTypes } from './user.contant';
import { actionTypes } from './action.constant';
import MyTable from 'components/MyTable/MyTable';
import classes from 'styles/classes.module.scss'
import { isArray, upperFirst } from 'lodash';
import moment from 'moment';
import PeriodSelection from './PeriodSelection';
import { isNull } from 'helpers';
import { useStoreUtils } from 'hooks/useStoreUtils';

const GeneralTableView = ({
	initialSearchParams = {},
	fetchType: fetchTypeReceived,
	type,
	columns: columnsReceived = null,
	navigable = false,
	afterFetchAdding = null,
	searchDates = true,
	searchDatesLabel = null,
	orderColumns: orderColumnsFromParams = null,
	total = null,
	beforeDate = false
}) => {
	const { state: { products } } = useStoreUtils()
	const orderColumns = useMemo(() => orderColumnsFromParams || [
		"Type d'action",
		"Nom du produit parent",
		"Nom du produit enfant",
		"Nom",
		"Client ID",
		"Nom du client",
		"Nom de l'utilisateur",
		"Adresse",
		"Prix",
		"TVA",
		"Capacité",
		"Type",
		"Defectueux",
		"Produit",
		"Quantité",
		"Quantité réelle",
		"Indice",
		"Entrepot",
		"Depuis l'entrepot",
		"Vers l'entrepot",
		"Utilisateur",
		"Utilisateur transféré",
		"Date",
		"Statut",
		...(products || []).map(({ name }) => `Quantité de ${name}`),
	], [orderColumnsFromParams, products])
	const navigate = useNavigate()
	const { fetchApi } = useApi()
	const { pathname } = useLocation()
	const [searchParamsFromHook] = useSearchParams()
	const searchParams = useMemo(() => {
		Object.entries(initialSearchParams)
			.map(([key, value]) => searchParamsFromHook.set(key, value))
		return searchParamsFromHook
	}, [searchParamsFromHook, initialSearchParams])
	const fetchType = useMemo(() => fetchTypeReceived || pathname.replace('/', ''), [pathname, fetchTypeReceived])
	const [datas, setDatas] = useState([])
	const types = useMemo(() => {
		switch (type) {
			case 'user':
				return userTypes
			case 'action':
				return actionTypes
			default:
				return {};
		}
	}, [type])

	const columns = useMemo(() => {
		if (!datas || datas.length === 0) {
			return []
		}
		if (columnsReceived) {
			return columnsReceived
		}
		const some = (fieldToCheck, canBeNull = false) => datas.some(e => {
			return isArray(fieldToCheck) ? fieldToCheck.some(
				field => !isNull(e[field]) || (canBeNull && e[field] !== undefined)
			) : (!isNull(e[fieldToCheck]) || (canBeNull && e[fieldToCheck] !== undefined))
		}
		)
		const isActionTable = !some('email')
		const isEntrepotTable = some('name')
		const generalColumnsObj = {
			"Type d'action": some(['reference', 'type'], true) && {
				field: 'type',
				title: "Type d'action",
				type: columnTypes.TEXT,
				render: ({ type, defectueux }) => {
					if (!!defectueux) {
						return 'Défectueux'
					}
					const elem =  Object.entries(actionTypes).find(
						([key, value]) => String(value) === String(type)
					)
					if (elem) {
						return elem[0]
					}
					return 'Non défini'
				} 
			},
			'Nom du produit parent': some('fromProductName') && { field: 'fromProductName', title: 'Nom du produit parent', type: columnTypes.TEXT },
			'Nom du produit enfant': some('toProductName') && { field: 'toProductName', title: 'Nom du produit enfant', type: columnTypes.TEXT },
			'Nom': some('name') && { field: 'name', title: 'Nom', type: columnTypes.TEXT },
			'Client ID': some('customerId') && { field: 'customerId', title: 'Client ID', type: columnTypes.TEXT },
			'Nom du client': (some('firstname') || some('lastname')) && {
				title: isActionTable ? 'Nom du client' : 'Nom de l\'utilisateur',
				render: ({ firstname, lastname }) => `${firstname || ''}${lastname && firstname ? ' ' : ''}${lastname || ''}`,
				type: columnTypes.TEXT,
				field: 'firstname'
			},
			'Nom de l\'utilisateur': (some('firstname') || some('lastname')) &&  {
				title: isActionTable ? 'Nom du client' : 'Nom de l\'utilisateur',
				render: ({ firstname, lastname }) => `${firstname || ''}${lastname && firstname ? ' ' : ''}${lastname || ''}`,
				type: columnTypes.TEXT,
				field: 'firstname'
			},
			'Adresse':
				some([
					'address',
					'city',
					'cp',
				]) && {
					title: 'Adresse', render: ({ address, cp, city }) =>
						`${address || ''}${cp && address ? ', ' : ''}${cp || ''}${city && cp ? ' ' : ''}${city || ''}`,
					type: columnTypes.TEXT,
					field: isEntrepotTable ? 'cp' : 'address'
				},
			'Prix': some('price ') && { field: 'price', title: 'Prix', type: columnTypes.TEXT },
			'TVA': some('tva ') && { field: 'tva', title: 'TVA', type: columnTypes.TEXT },
			'Capacité': some('capacity ') && { field: 'capacity', title: 'Capacité', type: columnTypes.TEXT },
			'Type': some('type ') && {
				title: 'Type',
				render: ({ type }) => (Object.entries(types).find(([key, value]) => value === type) || {})[0],
				type: columnTypes.TEXT,
				field: 'type'

			},
			'Defectueux': datas.some(elem => elem.type === actionTypes.INSTALLATION)
				&& some('defectueux')
				&& {
				title: 'Defectueux',
				render: elem => elem.defectueux ? 'Oui' : 'Non',
				type: columnTypes.TEXT,
				field: 'defectueux'
			},
			'Produit': some('productName') && {
				field: 'productName',
				title: 'Produit', render: ({ productName }) => productName, type: columnTypes.TEXT
			},
			'Quantité': some('quantity') && { field: 'quantity', title: 'Quantité', type: columnTypes.NUMERIC },
			'Quantité réelle': some(['indiceQuantity', 'quantity']) && {
				field: 'quantity',
				title: 'Quantité réelle', type: columnTypes.NUMERIC,
				render: ({ quantity, indiceQuantity }) => quantity * indiceQuantity
			},
			'Indice': some('indiceName') && {
				field: 'indiceName',
				title: 'Indice', render: ({ indiceName }) => indiceName, type: columnTypes.TEXT
			},
			"Depuis l'entrepot": some('entrepotFromName') && {
				field: 'entrepotFromName',
				title: "Depuis l'entrepot", render: ({ entrepotFromName }) => entrepotFromName, type: columnTypes.TEXT
			},
			"Vers l'entrepot": some('entrepotName') && {
				field: 'entrepotName',
				title: "Vers l'entrepot", render: ({ entrepotName, entrepotFromName }) => entrepotFromName && entrepotName, type: columnTypes.TEXT
			},
			'Entrepot': some('entrepotName') && {
				field: 'entrepotName',
				title: 'Entrepot', render: ({ entrepotName }) => entrepotName, type: columnTypes.TEXT
			},
			'Utilisateur': some('userFullName') && { field: 'userFullName', title: 'Utilisateur', render: ({ userFullName }) => userFullName, type: columnTypes.TEXT },
			'Utilisateur transféré': some('ownerFullName') && {
				field: 'ownerFullName', title: 'Utilisateur transféré',
				render: ({ ownerFullName, type }) => type === actionTypes.TRANSFER && ownerFullName,
				type: columnTypes.TEXT
			},
			'Date': some('createdAt') && { field: 'createdAt', title: 'Date', render: ({ createdAt }) => moment(createdAt).format('DD/MM/YYYY'), type: columnTypes.DATE },
			'Statut': some('status') && { field: 'status', title: 'Statut', render: ({ status }) => status, type: columnTypes.TEXT },
			...(products.reduce((obj, { name }) => ({
				...obj,
				[`Quantité de ${name}`]: some('productsQuantity') && {
					field: 'totalQuantity', title: `Quantité de ${name}`,
					render: ({ productsQuantity }) => productsQuantity.find(e => e.name === name)?.totalQuantity, type: columnTypes.NUMERIC
				}
			}), {}))
		}
		const getColumn = field => generalColumnsObj[field]

		return orderColumns.map(getColumn).filter(e => !!e)

	}, [datas, types, columnsReceived, orderColumns, products])

	const [nbTotalRow, setNbTotalRow] = useState()
	useEffect(() => {
		fetchApi(`${fetchType}/search?${searchParams.toString()}`)
			.then(async ([datas, count] = [[], 0]) => {
				if (afterFetchAdding) {
					const newDatas = await Promise.all(datas.map(async (data) => {
						const newData = await afterFetchAdding(data)
						return { ...data, ...newData }
					}))
					datas = newDatas
				}
				setDatas(datas)
				setNbTotalRow(count)
			})
			.catch(console.error)
	}, [searchParams, fetchType, fetchApi, afterFetchAdding])

	const onRowClick = useCallback((event, rowData) => navigate(`/${type}/${rowData.id}`), [navigate, type])

	return (
		<div style={{ maxWidth: "100%" }} className={classes.marginAuto}>
			{searchDates && <PeriodSelection labelText={searchDatesLabel} />}
			<MyTable
				beforeDate={beforeDate}
				total={total}
				counter={true}
				columns={columns}
				nbTotalRow={nbTotalRow}
				data={datas}
				title={upperFirst(fetchType)}
				options={{
					paging: true,
					pageSize: 100
				}}
				onRowClick={navigable ? onRowClick : () => { }}
			/>

		</div>
	);
};

export default GeneralTableView;