import classes from '../../styles/classes.module.scss'
import { columnTypes } from 'components/MyTable/MyTable.constant';
import { useMemo } from "react";
import { timeDateFormat } from "dates";
import MyOldTable from "components/MyTable/MyOldTable";
import cn from "classnames";
import statusStyle from "./Status.module.scss"

const InsertActionsTable = ({
	actions,
	title = 'Insertion des actions',
	isAudit = false
}) => {
	const columns = useMemo(() => [
		{
			field: 'isCreatable', title: 'Status', render: ({ isCreatable }) => {
				if (isCreatable === true) {
					return (
						<div className={cn(statusStyle.container)}>
							<div className={cn(statusStyle.circle)} style={{ backgroundColor: 'green' }} />
						</div>
					)
				} else {
					return (
						<div className={cn(statusStyle.container)} >
							<div className={cn(statusStyle.circle)}  style={{ backgroundColor: 'red' }} />
						</div>
					)
				}
			}
		},
		{
			field: 'isCreatable', title: 'Status message', render: ({ isCreatable }) => {
				if (isCreatable === true) {
					return (
						<div className={cn(statusStyle.container)}>
							<p>Ajouté</p>
						</div>
					)
				} else {
					return (
						<div className={cn(statusStyle.container)} >
							<p>supprimé à cause de {isCreatable}</p>
						</div>
					)
				}
			}
		},
		{ field: 'firstname', title: 'Nom', type: columnTypes.TEXT },
		{ field: 'address', title: 'Adresse', type: columnTypes.TEXT },
		{ field: 'description', title: 'Description', type: columnTypes.TEXT },
		{ field: 'createdAt', title: 'Date d\'audit', type: columnTypes.DATE, render: ({ createdAt }) => timeDateFormat(createdAt) },
		{ field: 'userName', title: 'Installateur', type: columnTypes.TEXT },
		{ field: 'productName', title: 'Produit', type: columnTypes.TEXT },
		{ field: 'realQuantity', title: 'Quantité', type: columnTypes.TEXT },
	].filter(e => !!e), [])
	return (
		<>
			<div style={{ maxWidth: "100%" }} className={classes.marginAuto}>
				<MyOldTable
					counter={true}
					nbTotalRow={actions.length}
					columns={columns}
					data={actions}
					title={title}
					options={{
						paging: true,
						pageSize: 1000
					}}
				/>
			</div>
		</>
	)
}

export default InsertActionsTable;