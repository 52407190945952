import React, { useEffect, useState } from 'react';
import classes from 'styles/classes.module.scss'
import { useApi } from 'hooks/useApi';
import { useParams } from 'react-router-dom';
import InsertActionsTable from './InsertActionsTable';

const InsertedActions = () => {
	const insertedActionsId = Number(useParams().insertedActionsId)
	const { fetchApi } = useApi()

	const [actions, setActions] = useState([])

	useEffect(() => {
		fetchApi(`insertedAction?id=${insertedActionsId}`)
			.then(({ actions }) => {
				setActions(actions)
			})
	}, [insertedActionsId, fetchApi])
	return (
		<div style={{ maxWidth: "100%" }} className={classes.marginAuto}>
			<InsertActionsTable
				actions={actions}
			/>
		</div>
	);
};

export default InsertedActions;