import Button from 'components/Button';
import React, { useCallback, useEffect, useMemo } from 'react';
import classes from '../../Home.module.scss'
import { useAuth } from 'hooks/useAuth';
import { useStoreUtils } from 'hooks/useStoreUtils';
import { useApi } from 'hooks/useApi';
import { isAuthenticated } from 'utils/Auth';
import { useLocation } from 'react-router-dom';

const Header = () => {
	const { state: { user }, isAdmin, dispatch } = useStoreUtils()
	const { logout } = useAuth()
	const { regenerateToken } = useApi()
	const location = useLocation();

	useEffect(() => {
		if (isAuthenticated()) {
			regenerateToken()
		}
	}, [])



	const complements = useMemo(() => {
		let index = 0;
		let components = [];

		const isActive = (path) => {
			return new RegExp(`^${path}$`).test(location.pathname);
		};

		const createButton = (type, link, text, isActive, func = () => { }) => (
			<Button
				type={type}
				key={index++}
				link={link}
				classNameFromFile={isActive ? 'active' : ''}
				onClick={func}
			>
				{text}
			</Button>
		);

		components.push(createButton("black", "", "DÉCONNEXION", false, logout));

		components.push(createButton("primary", "/entrepots", "Tous les entrepots", isActive("/entrepots")));
		components.push(createButton("primary", "/admin/users", "Tous les utilisateurs", isActive("/admin/users")));
		components.push(createButton("primary", "/products", "Tous les produits", isActive("/products")));
		components.push(createButton("primary", "/actions", "Toutes les actions", isActive("/actions")));
		components.push(createButton("primary", "/relations", "Toutes les relations", isActive("/relations")));

		if (isAdmin) {
			components.push(createButton("primary", "/action/create?type=0", "Créer un dépôt", isActive("/action/create?type=0")));
			components.push(createButton("primary", "/action/create?type=1", "Créer un retrait", isActive("/action/create?type=1")));
			components.push(createButton("primary", "/action/create?type=2", "Créer une installation", isActive("/action/create?type=2")));
			components.push(createButton("primary", "/product/create", "Créer un produit", isActive("/product/create")));
			components.push(createButton("primary", "/entrepot/create", "Créer un entrepot", isActive("/entrepot/create")));
			components.push(createButton("primary", "/relation/create", "Créer une relation", isActive("/relation/create")));
			components.push(createButton("primary", "/admin/create", "Créer un nouvel utilisateur", isActive("/admin/create")));
			components.push(createButton("primary", "/action/upload", "Charger un CSV d'actions", isActive("/action/upload")));
			components.push(createButton("primary", "/insertedActions", "Liste des insertions", isActive("/insertedActions")));
		}

		components.push(createButton("primary", "/settings", "Mon compte", isActive("/settings")));

		return components;
	}, [isAdmin, logout, location]);

	const updateDatesCompta = useCallback(({ startDate, endDate }) => {
		return dispatch({
			type: 'UPDATE_DATES', payload: {
				startDate,
				endDate
			}
		})
	}, [dispatch])

	if (!!!user) {
		return null;
	}

	return (
		<div className={classes.header}>
			<div>
				{/*
        <PeriodSelection updateFunc={updateDatesCompta} labelText={'Audit'} />
        <PeriodSelection updateFunc={updateDatesCompta} labelText={'Export'} />
        <DropDownMenu
          defaultOption="status"
          options={[
            'Visité',
            'Payé',
            'Envoie en facturation'
          ]}
          defaultStateText="Tous les statuts"
        />
        */}
			</div>
			<div className={classes.buttonsContainer}>
				{complements}
			</div>
		</div>
	);
};

export default Header;
