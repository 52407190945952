import FormComponent from 'components/Form/Form';
import toast from 'components/toast';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import classes from 'styles/classes.module.scss'
import { useApi } from 'hooks/useApi';
import { Store } from 'store/store';

const IndiceCreate = () => {
	const [params] = useSearchParams()
	const product = useMemo(() => params.get('product'), [params])
	const { state: { user } } = useContext(Store)
	const { fetchApi } = useApi()
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()

	const create = useCallback(async (infos) => {
		setLoading(true)
		try {
			const indice = await fetchApi('indice', { body: { ...infos, owner: user.id, product } })
			toast.success('Indice créé.')
			setLoading(false)
			navigate('/product/' + product)
		} catch (error) {
			setLoading(false)
			toast.error('Erreur durant la création de l\'indice: ' + error.message)
		}
	}, [user, fetchApi, navigate, product])

	const inputs = useMemo(() => [
		{
			type: 'select',
			name: 'name',
			label: 'Nom',
			required: true,
			silentRequired: true,
			options: ['Palette', 'Carton', 'Unité', 'Demi palette'].map((name, index) => ({
				key: name,
				value: name,
				text: name
			})),
		},
		{
			type: 'number',
			name: 'quantity',
			label: 'Quantié du produit',
			required: true,
			silentRequired: true,
		},
	].filter(e => !!e), [])


	return (
		<div className={classes.marginAuto} style={{ minHeight: 350 }}>
			<h2>Création de l'indice</h2>
			<FormComponent
				loading={loading}
				inputs={inputs}
				sendForm={create}
				centered={true}
				responsive={false}
				segment={false}
			/>
		</div>
	);
};

export default IndiceCreate;