import React, { useCallback, useEffect, useState } from 'react';
import classes from './CheckBoxInput.module.scss'
import classNames from 'classnames';

const CheckBoxInput = ({
	onUpdate = () => { },
	checked = false,
	moreClass = ''
}) => {
	const onClick = useCallback((evt) => {
		onUpdate()
	}, [onUpdate])
	return (
		<div className={classNames(classes.emptySquare, checked && classes.checked, moreClass)} onClick={onClick}>
			{checked && '✓'}
		</div>
	);
};

export default CheckBoxInput;