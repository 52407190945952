import FormComponent from 'components/Form/Form';
import toast from 'components/toast';
import { getOnlyUpdatedValues } from 'helpers';
import { isEmpty, upperFirst } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import classes from 'styles/classes.module.scss'
import classNames from 'classnames';
import Button from 'components/Button/Button';
import { useApi } from 'hooks/useApi';
import moment from 'moment';
import { actionStatuses, actionTypes } from './action.constant';

const Action = () => {
	const [params] = useSearchParams()

	const { fetchApi } = useApi()
	const navigate = useNavigate()
	const id = Number(useParams().actionId)
	const [action, setAction] = useState({})
	const [loading, setLoading] = useState(false)
	const [products, setProducts] = useState([])
	const [entrepots, setEntrepots] = useState([])
	const [users, setUsers] = useState([])
	const [indices, setIndices] = useState([])
	const product = useMemo(() => Number(action.product || params.get('product') || 0) || null, [params, action])
	const typeReadeable = useMemo(() => action ? Object.keys(actionTypes)[action.type] : '', [action])
	const isEntrepotTransfer = useMemo(() => action.type === actionTypes.TRANSFER && !!action.entrepotFrom, [action])

	useEffect(() => {
		fetchApi('action?id=' + id)
			.then(actionReceived => {
				if (!actionReceived) {
					toast.error('Action introuvable')
					return navigate('/actions')
				}
				setAction(actionReceived)
				fetchApi('products')
					.then(setProducts)
				fetchApi('users')
					.then(setUsers)
				fetchApi('entrepots')
					.then(setEntrepots)
			})
	}, [fetchApi, id])

	useEffect(() => {
		if (product != null) {
			fetchApi('indices?product=' + product)
				.then(setIndices)
		}
	}, [product, fetchApi])

	const inputs = useMemo(() => [
		{
			type: 'autocomplete',
			name: 'product',
			label: 'Produit',
			required: true,
			silentRequired: true,
			value: action.product ? String(action.product) : null,
			options: products.map((product) => ({
				key: String(product.id),
				value: String(product.id),
				text: product.name
			})),
		},
		isEntrepotTransfer && {
			type: 'autocomplete',
			name: 'entrepotFrom',
			label: 'Depuis l\'entrepot',
			required: true,
			silentRequired: true,
			value: action.entrepotFrom ? String(action.entrepotFrom) : null,
			options: entrepots.map((entrepot) => ({
				key: String(entrepot.id),
				value: String(entrepot.id),
				text: entrepot.name
			})),
		},
		(![actionTypes.INSTALLATION, actionTypes.TRANSFER].includes(action.type) || isEntrepotTransfer) && {
			type: 'autocomplete',
			name: 'entrepot',
			label: 'Entrepot',
			required: true,
			silentRequired: true,
			value: action.entrepot ? String(action.entrepot) : null,
			options: entrepots.map((entrepot) => ({
				key: String(entrepot.id),
				value: String(entrepot.id),
				text: entrepot.name
			})),
		},
		!isEntrepotTransfer &&{
			type: 'autocomplete',
			name: 'user',
			label: action.type === actionTypes.TRANSFER ? 'Vers ce collaborateur' : 'Collaborateur',
			...([actionTypes.RETRAIT, actionTypes.INSTALLATION, actionTypes.TRANSFER].includes(action.type) ? {
				required: true,
				silentRequired: true,
			} : {}),
			value: action.user ? String(action.user) : null,
			options: users.map((user) => ({
				key: String(user.id),
				value: String(user.id),
				text: upperFirst(user.firstname) + ' ' + upperFirst(user.lastname)
			})),
		},
		{
			type: 'autocomplete',
			name: 'indice',
			label: 'Indice',
			required: true,
			silentRequired: true,
			value: action.indice != null ? String(action.indice) : null,
			options: indices.map((indice) => ({
				key: String(indice.id),
				value: String(indice.id),
				text: indice.name + ' x' + indice.quantity
			})),
		},
		{
			type: 'number',
			name: 'quantity',
			label: 'Quantité',
			value: action.quantity,
			required: true,
			silentRequired: true,
		},
		...(action.type === actionTypes.INSTALLATION ? [{
			type: 'text',
			name: 'customerId',
			value: action.customerId,
			label: 'ID Client',
		},

		{
			type: 'text',
			name: 'lastname',
			value: action.lastname,
			label: 'Nom Client',
		},

		{
			type: 'text',
			name: 'firstname',
			value: action.firstname,
			label: 'Prénom Client',
		},

		{
			type: 'text',
			name: 'address',
			value: action.address,
			label: 'Adresse Client',
		}] : []),
		{
			type: 'textArea',
			name: 'reference',
			label: 'Référence',
			value: action.reference,
		},
		{
			type: 'textArea',
			name: 'description',
			label: 'Description',
			value: action.description,
		},
		{
			type: 'date',
			name: 'createdAt',
			label: 'Date',
			value: moment(action.createdAt).format('YYYY-MM-DD') || new Date().toISOString().split('T')[0],
			required: true,
			silentRequired: true,
		},
		![actionTypes.INSTALLATION, actionTypes.TRANSFER].includes(action.type) && {
			type: 'autocomplete',
			name: 'status',
			label: 'Statut',
			value: action.status,
			options: Object.keys(actionStatuses).map((status) => ({
				key: String(status),
				value: String(status),
				text: status
			})),
		},
	].filter(e => !!e), [action, products, indices, entrepots, users, isEntrepotTransfer])

	const update = useCallback(async (values) => {
		setLoading(true)
		try {
			const updatedValues = getOnlyUpdatedValues({
				...action,
			}, values, true)
			if (isEmpty(updatedValues)) {
				toast.error('Aucune info n\'a changé.')
				return
			}
			await fetchApi('action?id=' + id, {
				method: 'PUT',
				body: { ...updatedValues },
			})
			setAction(action => ({ ...action, ...updatedValues }))
			toast.success('Infos actualisées')
		} catch (error) {
			toast.error('Erreur durant l\'actualisation des infos :' + error.message)
			console.log('error', error)
			return
		} finally {
			setLoading(false)
		}
	}, [fetchApi, id, action])

	const remove = useCallback(async () => {
		setLoading(true)
		try {
			await fetchApi('action?id=' + id, {
				method: 'DELETE',
			})
			toast.success('Supprimée')
			setLoading(false)
			if (action.entrepot) {
				return navigate('/entrepot/' + action.entrepot)

			} else {
				return navigate('/admin/user/' + action.user)
			}
		} catch (error) {
			toast.error('Erreur durant la suppression :' + error.message)
			console.log('error', error)
			setLoading(false)
			return
		}
	}, [action, fetchApi, id, navigate])
	const transferOwner = useMemo(() => action.type === actionTypes.TRANSFER ?
		(" Depuis l'utilisateur " + upperFirst(action.ownerObject?.firstname) + ' ' + upperFirst(action.ownerObject?.lastname))
		: '', [action])

	return (
		<>
			<div className={classNames(
				classes.marginAuto,
				// classes.flex,
				// classes.flexWrap
			)}>
				<Button type='danger' onClick={remove}>Supprimer {typeReadeable}</Button>
				<div className={classNames(
					classes.marginAuto,
					classes.flex,
					classes.flexWrap
				)}>
					<div>
						<h3>Informations de {typeReadeable}{transferOwner}</h3>
						<FormComponent
							loading={loading}
							inputs={inputs}
							sendForm={update}
							centered={true}
							responsive={false}
							segment={false}
							messages={{ submit: 'Actualiser les infos' }}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default Action;