import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import CheckBoxInput from './CheckBoxInput';
import classes from './DefectueuxFilter.module.scss';


const DefectueuxFilter = () => {
	const [searchParams] = useSearchParams()
	const defectueux = useMemo(() => searchParams.get('defectueux') || '', [searchParams])
	const navigate = useNavigate();

	const changeDefectueux = useCallback(() => {
		if (defectueux) {
			searchParams.delete('defectueux');
		} else {
			searchParams.set('defectueux', 'true');
		}
		navigate(`?${searchParams.toString()}`, { replace: true });
	}, [defectueux, searchParams, navigate]);

	return (
		<div className={classNames(classes.container)}>
			<label className={classNames(classes.label)} htmlFor="defectueux">Que les défectueux</label>
			<CheckBoxInput
				moreClass={classes.emptySquare}
				onUpdate={changeDefectueux}
				checked={!!defectueux}
			/>
		</div>
	);
};

export default DefectueuxFilter;
